import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Hero';
import cleanCrumbLabels from '../utils/cleanCrumbLabels';

export default function Template({ pageContext, data, location }) {
	const {
		breadcrumb: { crumbs }
	} = pageContext;
	const { mdx } = data;
	const { frontmatter, body } = mdx;

	return (
		<Layout>
			<Seo title={frontmatter.title} description={frontmatter.description} />
			<div className="o-wrapper">
				<div className="o-row-2">
					<Hero headline={frontmatter.headline} image={frontmatter.heroImage} />
				</div>
				<div className="o-row-3 o-row-6-d">
					<Breadcrumb location={location} crumbSeparator=" / " crumbs={cleanCrumbLabels(crumbs)} />
				</div>
				<div className="o-row-3 o-row-6-d">
					<div className="c-content-page">
						<MDXRenderer>{body}</MDXRenderer>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query ($slug: String!) {
		mdx(slug: { eq: $slug }) {
			body
			frontmatter {
				title
				description
				headline
				heroImage {
					childImageSharp {
						gatsbyImageData(width: 890, placeholder: BLURRED, formats: AUTO)
					}
				}
			}
		}
	}
`;
