import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Hero = ({ headline, image, alt = '' }) => {
	return (
		<section className="c-content-hero">
			{image && (
				<div className="c-content-hero__img">
					<GatsbyImage image={getImage(image)} alt={alt} />
				</div>
			)}
			<h1 className="c-content-hero__headline">{headline}</h1>
			<div className="c-content-hero__divider"></div>
		</section>
	);
};

export default Hero;
